import { getUrlPathWithoutLanguage } from '~/locale';
import { useRootLoaderData } from './useRootLoaderData'

export const useLocalePath = () => {
  return ( path: string ) => {
    if ( path === '/' ) {
      path = '';
    }

    const rootLoaderData = useRootLoaderData();
    const pathWithoutLanguage = getUrlPathWithoutLanguage( path );

    if ( rootLoaderData?.userLanguage === 'en' ) {
      return pathWithoutLanguage;
    }
  
    return `/${rootLoaderData?.userLanguage}${pathWithoutLanguage}`;
  }
}
